import {useAppSelector} from "../app/hooks";
import {selectStart} from "../features/agenda/week/weekAgendaSlice";
import {useGetWeeklyReportQuery} from "../app/services/weeklyReportApi";
import {Box, Divider, LinearProgress, Stack, Typography} from "@mui/joy";
import {getISOWeek, getYear, intervalToDuration, parseISO} from "date-fns";
import {AgendaWeekViewSidebarLoading} from "./Loading";
import {useTranslation} from "react-i18next";
import CurrentEventShortInfo from "./events/CurrentEventShortInfo";

const formatDuration = (seconds: number) => {
    const dura = []
    const time = intervalToDuration({start: 0, end: seconds * 1000})
    if (time.hours) {
        dura.push(time.hours < 10 ? "0" + time.hours : time.hours)
    } else {
        dura.push("00")
    }
    if (time.minutes) {
        dura.push(time.minutes < 10 ? "0" + time.minutes : time.minutes)
    } else {
        dura.push("00")
    }
    return dura.join(":")
}

const WorkautWeeklyStatsView = () => {
    const {t} = useTranslation("glossary");
    const start = parseISO(useAppSelector(state => selectStart(state)));
    const year = getYear(start);
    const week = getISOWeek(start)
    const {data, error, isLoading} = useGetWeeklyReportQuery({year});
    if (isLoading) {
        return <AgendaWeekViewSidebarLoading/>
    }
    if (error) {
        return <div>error</div>
    }
    if (data === undefined) {
        return <>undef</>
    }
    if (!data[year] || !data[year][week]) {
        return <></>
    }
    const report = data[year][week]

    return (
        <Box>
            <Box sx={{display: "grid", py: 2}}>
                <Box><Typography pb={1} level="body-md" textColor="text.tertiary">{t("total")}</Typography></Box>
                <Box>
                    <LinearProgress
                        determinate
                        value={Math.min(100, report.totalFactDuration / report.totalPlannedDuration * 100)}
                    />
                </Box>
                <Box>
                    <Typography mt={1} level="body-sm" textColor="text.tertiary">
                        {formatDuration(report.totalFactDuration)} / {formatDuration(report.totalPlannedDuration)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: "grid", py: 2}}>
                <Box><Typography pb={1} level="body-md" textColor="text.tertiary">{t("swimming")}</Typography></Box>
                <Box>
                    <LinearProgress
                        determinate
                        value={Math.min(100, report.swimmingFactDuration / report.swimmingPlannedDuration * 100)}
                    />
                </Box>
                <Box>
                    <Typography mt={1} level="body-sm" textColor="text.tertiary">
                        {formatDuration(report.swimmingFactDuration)} / {formatDuration(report.swimmingPlannedDuration)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: "grid", py: 2}}>
                <Box><Typography pb={1} level="body-md" textColor="text.tertiary">{t("cycling")}</Typography></Box>
                <Box>
                    <LinearProgress
                        determinate
                        value={Math.min(100, report.cyclingFactDuration / report.cyclingPlannedDuration * 100)}
                    />
                </Box>
                <Box>
                    <Typography mt={1} level="body-sm" textColor="text.tertiary">
                        {formatDuration(report.cyclingFactDuration)} / {formatDuration(report.cyclingPlannedDuration)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: "grid", py: 2}}>
                <Box><Typography pb={1} level="body-md" textColor="text.tertiary">{t("running")}</Typography></Box>
                <Box>
                    <LinearProgress
                        determinate
                        value={Math.min(100, report.runningFactDuration / report.runningPlannedDuration * 100)}
                    />
                </Box>
                <Box>
                    <Typography mt={1} level="body-sm" textColor="text.tertiary">
                        {formatDuration(report.runningFactDuration)} / {formatDuration(report.runningPlannedDuration)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

const AgendaWeekViewSidebar = () => {
    return (
        <Stack direction="column" spacing={2}>
            <CurrentEventShortInfo screen="desktop"/>
            <Divider/>
            <WorkautWeeklyStatsView/>
        </Stack>
    )
}

export default AgendaWeekViewSidebar