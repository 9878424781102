import i18n, {InitOptions} from "i18next";
import {initReactI18next} from "react-i18next";
import HttpBackend, {HttpBackendOptions} from "i18next-http-backend";

const options = {
    lng: "ru",
    pluralSeparator: '-',
    interpolation: {
        escapeValue: false 
    },
    backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
} as InitOptions<HttpBackendOptions>

// noinspection JSIgnoredPromiseFromCall
i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init<HttpBackendOptions>(options);

export default i18n
