import React, { useCallback, useEffect, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useAppAction } from "../../../features/app/hooks";
import { IAlert } from "../../../app/types/Alert";

type TAlertItem = {
    id: number;
    alert: IAlert;
}

const AlertItem = ({ alert, id }: TAlertItem) => {
    const { onAlertClose } = useAppAction()
    const timeout: any = useRef<any>(null)

    const handleClose = useCallback(() => {
        onAlertClose(id);
    }, [id, onAlertClose]);

    useEffect(() => {
        if (alert) {
            timeout.current = setTimeout(() => {
                handleClose();
            }, 4000);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [alert, id, onAlertClose, handleClose]);

    return (
        <Snackbar
            open={!!alert}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleClose}
        >
            <Alert severity={alert.variant} variant="filled" >
                {alert.message}
            </Alert>
        </Snackbar>
    )
}

export default React.memo(AlertItem);