import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { closeAlert, openAlert } from "./appSlice";
import { AppDispatch, RootState } from "../../app/store";
import { IAlert } from "../../app/types/Alert";

export const getApp = (state: RootState) => state.app;

export function useAppAction() {
    const dispatch = useDispatch<AppDispatch>()

    const onAlertOpen = useCallback((alert: IAlert) => {
        dispatch(openAlert(alert))
    }, [dispatch])

    const onAlertClose = useCallback((id?: number) => {
        dispatch(closeAlert(id))
    }, [dispatch])

    return useMemo(() => ({
        onAlertOpen,
        onAlertClose,
    }), [onAlertOpen, onAlertClose])
}

export function useAppState() {
    return useSelector(getApp)
}