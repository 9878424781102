import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlert } from "../../app/types/Alert";

export interface AppState {
  alerts: IAlert[]
}

const initialState: AppState = {
  alerts: [],
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openAlert: (state, action: PayloadAction<IAlert>) => {
      state.alerts = [...state.alerts, action.payload]
    },
    closeAlert: (state, action: PayloadAction<number | undefined>) => {
      if (action.payload !== undefined) {
        state.alerts = state.alerts.filter((_, id) => id !== action.payload)
      } else {
        state.alerts = state.alerts.length > 1 ? state.alerts.slice(1) : []
      }
    },
  }
})

export const { openAlert, closeAlert } = appSlice.actions
export default appSlice.reducer
