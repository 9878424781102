import {createApi} from '@reduxjs/toolkit/query/react'
import baseQuery from "./platformBaseQuery";


export type PlanView = {
    id: string
    planType: "TRIATHLON" | "SWIMMING" | "CYCLING" | "RUNNING"
    planCategory: "PRIMARY_EVENT" | "SECONDARY_EVENT" | "TEST" | "CAMP" | "RECOVERY"
    translations: {
        [lang: string]: {
            title: string
            description: string
        }
    }
    minWeeks: number
    maxWeeks: number
};

export type PlanListView = Array<PlanView>;

// noinspection TypeScriptValidateTypes
export const planApi = createApi({
    reducerPath: 'planApi',
    baseQuery,
    endpoints: (builder) => ({
        getPlanList: builder.query<PlanListView, void>({
            query: () => ({
                url: `/api/v1/planner/plans`,
            }),
            transformResponse: (response: any, meta, arg) => {
                return response.map((plan: any) => {
                    plan.translations = plan.translations.reduce((acc: any, curr: any) => (acc[curr.lang] = curr), {});
                    return plan
                })
            },
        }),
    })
});

export const {
    useGetPlanListQuery
} = planApi
