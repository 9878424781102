import { Box, Stack, Typography } from "@mui/joy"
import { useTranslation } from "react-i18next";


const EventTypeView = () => {
    // TODO(Запрос тип старта)
    return (
        <Typography></Typography>
    )
}

const EventRestTime = () => {
    const {t} = useTranslation("translation");
    const numberOfWeeks: number = 0
    return (
        <Typography>{t("numberOfWeeks", {count: numberOfWeeks})}</Typography>
    )
}


const CurrentEventShortInfo = ({screen}: { screen?: "mobile" | "desktop" }) => {
    const {t} = useTranslation("glossary");
    if (screen === "desktop"){
        return (
            <Box>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Typography>{t("event_type")}:</Typography>
                        <EventTypeView/>
                    </Stack>
                    <Stack direction="row" spacing={2}> 
                    <Typography>{t("event_rest_time")}:</Typography>
                    <EventRestTime/>
                    </Stack>
                </Stack>
            </Box>
        )
    } else {
        return (
        <Box sx = {{
            display: "flex"
        }}>
           <EventTypeView/>
           <EventRestTime/>
        </Box>
        )
    }

}

export default CurrentEventShortInfo