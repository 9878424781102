import { Fragment, useEffect, useMemo, useState } from "react"
import { Field, Form, FormSpy } from "react-final-form"
import { add, eachDayOfInterval, endOfWeek, format, getISODay, Interval, min, parseISO, startOfWeek, sub } from "date-fns"
import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    List,
    ListDivider,
    ListItem,
    ListItemDecorator,
    listItemDecoratorClasses,
    ModalClose,
    ModalDialog,
    ModalOverflow,
    Option,
    optionClasses,
    Select,
    Sheet,
    Typography
} from "@mui/joy"
import SwimmingIcon from "@mui/icons-material/Pool"
import CyclingIcon from "@mui/icons-material/DirectionsBike"
import RunningIcon from "@mui/icons-material/DirectionsRun"
import { Check, KeyboardArrowRight } from "@mui/icons-material"
import { Backdrop, CircularProgress } from "@mui/material"
import { CreateEvent, useCreateEventMutation } from "../app/services/eventApi"
import JoyDatePicker from "./JoyDatePicker"
import { useGetPlanListQuery } from "../app/services/planApi";
import { PlanBuilderDialogLoading } from "./Loading";
import { useTranslation } from "react-i18next";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import WorkoutIcon from "./WorkoutIcon";
import { useAppAction } from "../features/app/hooks"

const Item = ({
    sportType,
}: { sportType: "SWIMMING" | "CYCLING" | "RUNNING" | "GYM" }) => {
    const { t } = useTranslation("workout");

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: "sm",
                p: 1.5,
                mb: 1.5,
                width: "100%",
                cursor: "pointer"
            }}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 0.5
            }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Box sx={{ display: "flex", fontSize: "1.1rem", flexDirection: "column", alignItems: "center" }}>
                        <WorkoutIcon sportType={sportType} />
                    </Box>
                    <Typography ml={0.5} noWrap level="body-sm">
                        {t("workout_card__sport_type_" + sportType)}
                    </Typography>
                </Box>
            </Box>
            <Box mt={5} sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 0.5,
                width: "100%",
                height: "32px",
                background: "rgb(9, 107, 222)"
            }}>
            </Box>
        </Sheet>
    )
}
const priorities = ["A", "B", "C"]
const validate = (values: CreateEvent) => {
    const errors: Partial<Record<keyof CreateEvent, string>> = {}
    if (!values.title) {
        errors.title = "form_validation__title_empty"
    }
    if (!values.dateFrom) {
        errors.dateFrom = "form_validation__date_from_empty"
    }
    if (!values.dateTo) {
        errors.dateTo = "form_validation__date_to_empty"
    }
    // if (!values.planId) {
    //     errors.planId = "form_validation__event_type_empty"
    // }
    if (!values.goalType) {
        errors.goalType = "form_validation__priority_empty"
    }
    return errors
}
type CreateEventCalendarSlots = Pick<CreateEvent, "calendarSlots">
const initialCalSlots: CreateEventCalendarSlots = {
    calendarSlots: [
        { sportType: "SWIMMING", dow: 2 },
        { sportType: "RUNNING", dow: 3 },
        { sportType: "CYCLING", dow: 4 },
        { sportType: "SWIMMING", dow: 5 },
        { sportType: "RUNNING", dow: 6 },
        { sportType: "CYCLING", dow: 7 },
    ]
}

type TPlanBuilderDialogProps = {
    submit: () => void;
}

const PlanBuilderDialog = ({ submit }: TPlanBuilderDialogProps) => {
    const { t, i18n } = useTranslation("planbuilder");
    const lang = i18n.language
    const { onAlertOpen } = useAppAction();
    const initialDate = new Date();
    const [saving, setSaving] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<Date | null>(initialDate);
    const [planId, setPlanId] = useState<string | null>(null);
    const [event, setEvent] = useState<CreateEventCalendarSlots>(initialCalSlots);
    const [, setWidth] = useState<number>(window.innerWidth);
    const { data, error, isLoading } = useGetPlanListQuery();
    const [createEvent] = useCreateEventMutation()

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const eventByDow = useMemo(() => {
        const acc: { [dow: number]: Array<"SWIMMING" | "CYCLING" | "RUNNING" | "GYM"> } = {}
        event.calendarSlots.forEach(s => {
            acc[s.dow] ||= []
            acc[s.dow].push(s.sportType);
        });
        return acc
    }, [event.calendarSlots]);

    if (isLoading) {
        return <PlanBuilderDialogLoading />
    }

    if (error) {
        return <div>error...</div>
    }

    const planById: { [id: string]: any } = {}
    const plansByType: any[] = []
    const plansByCategory: any[] = []
    data!.forEach((i: any) => {
        planById[i.id] = i
        plansByType[i.planType] ||= []
        plansByType[i.planType].push(i);
        plansByCategory[i.planCategory] ||= []
        plansByCategory[i.planCategory].push(i);
    });
    const plan = planId ? planById[planId] : null;
    const minDate = dateFrom && plan ? add(dateFrom, { weeks: plan.minWeeks }) : null
    const maxDate = dateFrom && plan ? add(dateFrom, { weeks: plan.maxWeeks }) : null
    const isInvalidDateTo = (date: Date) => {
        if (minDate && date < minDate) {
            return true;
        }
        if (maxDate && date > maxDate) {
            return true;
        }
        return getISODay(date) < 5;
    };
    const variants = [add(dateFrom ? dateFrom : initialDate, { months: 3 })];
    if (maxDate) {
        variants.push(maxDate)
    }
    let closestAvailableDateIn3Months = min(variants);
    while (isInvalidDateTo(closestAvailableDateIn3Months)) {
        closestAvailableDateIn3Months = sub(closestAvailableDateIn3Months, { days: 1 })
    }
    const formattedInitialDate = format(initialDate, "yyyy-MM-dd");
    const formattedClosestDate = format(closestAvailableDateIn3Months, "yyyy-MM-dd");

    const offseasonInitialValues = {
        dateFrom: formattedInitialDate,
        includeTest: true,
        goalType: "A",
        dateTo: formattedClosestDate,
        ...initialCalSlots
    } as Partial<CreateEvent>
    const defaultInitialValues = {
        dateFrom: formattedInitialDate,
        includeTest: true,
        goalType: "A",
        calendarSlots: [
            { sportType: "SWIMMING", dow: 2 },
            { sportType: "SWIMMING", dow: 5 },
            { sportType: "RUNNING", dow: 3 },
            { sportType: "RUNNING", dow: 6 },
            { sportType: "CYCLING", dow: 4 },
            { sportType: "CYCLING", dow: 7 },
            { sportType: "GYM", dow: 4 }
        ],
        dateTo: formattedClosestDate,
    } as Partial<CreateEvent>
    const initialValues = plan && plan.id === '5d1862e0-e52a-4d0c-a918-4a81e197986a' ? offseasonInitialValues : defaultInitialValues
    // Mon, Tue, Wed, ..., Sun
    const formatDOW = (date: Date) => format(date, lang === "ru" ? "cccccc" : "ccc");
    const dayRange = eachDayOfInterval({
        start: startOfWeek(initialDate),
        end: endOfWeek(initialDate)
    } as Interval)
    const onDragEnd = (result: DropResult) => {
        const { draggableId, destination } = result
        if (!draggableId || !destination) {
            return;
        }
        const [dow, sportType] = draggableId.split(":")
        const index = event.calendarSlots.findIndex(slot => slot.sportType === sportType && slot.dow === parseInt(dow))
        const calendarSlots = [...event.calendarSlots];
        calendarSlots[index].dow = parseInt(destination!.droppableId)
        setEvent({ calendarSlots })
    }
    const save = async (newEvent: CreateEvent) => {
        if (typeof planId !== 'string') throw new Error('Plan is not selected');
        setSaving(true)
        try {
            const finalEvent = { ...newEvent, ...event, planId };
            await createEvent(finalEvent).unwrap()
            setSaving(false)
            onAlertOpen({
                variant: "success",
                message: "Новый план был добавлен"
            })
            submit()
        } catch (error) {
            onAlertOpen({
                variant: "error",
                message: "Не удалось добавить план"
            })
        }
    }
    // const isMobile = width <= 768;
    // @ts-ignore
    return (
        <ModalOverflow>
            <ModalDialog
                layout="fullscreen"
                color="neutral"
                size="lg"
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
            >
                <ModalClose />
                <Typography
                    id="alert-dialog-modal-title"
                    component="h2"
                    startDecorator={
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 0.5
                        }}>
                            <SwimmingIcon />
                            <CyclingIcon />
                            <RunningIcon />
                        </Box>
                    }
                >
                    {t("dialog_title")}
                </Typography>
                <Divider />
                <Form<CreateEvent>
                    keepDirtyOnReinitialize
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={save}
                >
                    {formProps => (
                        <form onSubmit={formProps.handleSubmit}>
                            <Box sx={{
                                display: "flex",
                                alignItems: { md: "center" },
                                justifyContent: "center",
                                height: "100%",
                                width: "100%",
                                pt: { xs: 4, md: 0 },
                                // pb: {md: 20}
                            }}
                            >
                                <Box sx={{ my: 2, width: { xs: "100%", md: "50vw" } }}>
                                    <Box
                                        sx={{
                                            width: { sm: "100%", md: "50vw" },
                                            display: "grid",
                                            alignItems: "center",
                                            justifyItems: "space-between",
                                            gridTemplateColumns: {
                                                sm: "1fr",
                                                // md: "4fr 1fr"// TODO uncomment for priority
                                            },
                                            // gridTemplateRows: {
                                            //     sm: "2fr",
                                            //     md: "1fr"
                                            // },
                                            gap: 4
                                        }}
                                    >
                                        <Field name="title">
                                            {({ input, meta }) => (
                                                <FormControl size="lg">
                                                    <FormLabel>{t("form_field__title_label")}</FormLabel>
                                                    <Input
                                                        {...input}
                                                        autoFocus={true}
                                                        placeholder={t("form_field__title_placeholder")}
                                                        error={(t(meta.error) || meta.submitError) && meta.touched}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Box>
                                    <FormSpy subscription={{ pristine: true }}>
                                        {props => (
                                            <Box
                                                sx={{
                                                    display: {
                                                        xs: props.pristine ? "none" : "block",
                                                        md: "block"
                                                    },
                                                    visibility: {
                                                        xs: "visible",
                                                        md: props.pristine ? "hidden" : "visible"
                                                    },
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        mt: 6,
                                                        mb: 4,
                                                        width: { sm: "100%", md: "50vw" },
                                                        display: "grid",
                                                        alignItems: "center",
                                                        justifyItems: "space-between",
                                                        gridTemplateColumns: {
                                                            sm: "1fr",
                                                            md: "1fr 1fr"
                                                        },
                                                        gridTemplateRows: {
                                                            sm: "4fr",
                                                            md: "2fr"
                                                        },
                                                        gap: 4
                                                    }}
                                                >
                                                    <Field name="dateFrom">
                                                        {({ input, meta }) => (
                                                            <Box>
                                                                <JoyDatePicker
                                                                    label={t("form_field__date_from_label")}
                                                                    disablePast={true}
                                                                    disabled={formProps.values.goalType !== "A"}
                                                                    value={parseISO(input.value)}
                                                                    onChange={(newValue: Date | null) => {
                                                                        setDateFrom(newValue);
                                                                        return (newValue === null)
                                                                            ? input.onChange(newValue)
                                                                            : input.onChange(format(newValue, "yyyy-MM-dd"))
                                                                    }}
                                                                />
                                                                {(t(meta.error) || meta.submitError) && meta.touched && (
                                                                    <Typography
                                                                        color="warning"
                                                                        variant="plain"
                                                                        level="body-sm"
                                                                        gutterBottom
                                                                    >
                                                                        {t(meta.error) || meta.submitError}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Field>
                                                    <Field name="dateTo">
                                                        {({ input, meta }) => (
                                                            <Box>
                                                                <JoyDatePicker
                                                                    label={t("form_field__date_to_label")}
                                                                    shouldDisableDate={isInvalidDateTo}
                                                                    disablePast={true}
                                                                    value={parseISO(input.value)}
                                                                    onChange={(newValue: Date | null) => {
                                                                        return (newValue === null)
                                                                            ? input.onChange(newValue)
                                                                            : input.onChange(format(newValue, "yyyy-MM-dd"))
                                                                    }}
                                                                />
                                                                {(t(meta.error) || meta.submitError) && meta.touched && (
                                                                    <Typography
                                                                        color="warning"
                                                                        variant="plain"
                                                                        level="body-sm"
                                                                        gutterBottom
                                                                    >
                                                                        {t(meta.error) || meta.submitError}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Field>
                                                    <FormControl size="lg">
                                                        <FormLabel>{t("form_field__event_type_label")}</FormLabel>
                                                        <Field name="planId">
                                                            {({ input, meta }) => (
                                                                <Select
                                                                    value={input.value}
                                                                    // onChange={(e) => {
                                                                    //     console.log(e)
                                                                    // }}
                                                                    placeholder={t("form_field__event_type_placeholder")}
                                                                    slotProps={{
                                                                        listbox: {
                                                                            component: "div",
                                                                            sx: {
                                                                                maxHeight: 240,
                                                                                overflow: "auto",
                                                                                "--List-padding": "0px",
                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{ width: "100%" }}
                                                                >
                                                                    {Object.keys(plansByType).map((type: string, index) => (
                                                                        <Fragment key={index}>
                                                                            {index !== 0 && <ListDivider role="none" />}
                                                                            <List
                                                                                aria-labelledby={`select-group-${type}`}
                                                                                sx={{ "--ListItemDecorator-size": "28px" }}
                                                                            >
                                                                                <ListItem
                                                                                    id={`select-group-${type}`}
                                                                                    sticky
                                                                                >
                                                                                    <Typography
                                                                                        level="body-sm"
                                                                                        textTransform="uppercase"
                                                                                        letterSpacing="md"
                                                                                    >
                                                                                        {type}
                                                                                    </Typography>
                                                                                </ListItem>
                                                                                {/*@ts-ignore*/}
                                                                                {plansByType[type].map((plan: any) => (
                                                                                    <Option
                                                                                        onClick={(e) => {
                                                                                            setPlanId(plan.id)
                                                                                            input.onChange(plan.id);
                                                                                        }}
                                                                                        key={plan.id}
                                                                                        value={plan.id}
                                                                                        label={
                                                                                            <>
                                                                                                <Chip
                                                                                                    size="sm"
                                                                                                    color="neutral"
                                                                                                    sx={{
                                                                                                        borderRadius: "xs",
                                                                                                        mr: 1
                                                                                                    }}
                                                                                                >
                                                                                                    {type}
                                                                                                </Chip>{" "}
                                                                                                {plan.translations[lang].title}
                                                                                            </>
                                                                                        }
                                                                                        sx={{
                                                                                            [`&.${optionClasses.selected} .${listItemDecoratorClasses.root}`]: { opacity: 1 },
                                                                                            mx: 0.5,
                                                                                            my: 0.25,
                                                                                        }}
                                                                                    >
                                                                                        <>
                                                                                            <ListItemDecorator
                                                                                                sx={{ opacity: 0 }}>
                                                                                                <Check />
                                                                                            </ListItemDecorator>
                                                                                            {plan.translations[lang].title}
                                                                                        </>
                                                                                    </Option>
                                                                                ))}
                                                                            </List>
                                                                        </Fragment>
                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                    <FormControl size="lg">
                                                        <FormLabel>{t("form_field__event_priority_label")}</FormLabel>
                                                        <Field name="priority">
                                                            {({ input, meta }) => (
                                                                <Select
                                                                    value={input.value}
                                                                    // onChange={(e) => {
                                                                    //     console.log(e)
                                                                    // }}
                                                                    placeholder={t("form_field__event_priority_placeholder")}
                                                                    slotProps={{
                                                                        listbox: {
                                                                            component: "div",
                                                                            sx: {
                                                                                maxHeight: 240,
                                                                                overflow: "auto",
                                                                                "--List-padding": "0px",
                                                                            },
                                                                        },
                                                                    }}
                                                                    sx={{ width: "100%" }}
                                                                >
                                                                    {priorities.map((priority: string) => (
                                                                        <Option
                                                                            onClick={(e) => {
                                                                                input.onChange(priority);
                                                                            }}
                                                                            key={priority}
                                                                            value={priority}
                                                                        >
                                                                            {priority}
                                                                        </Option>

                                                                    ))}
                                                                </Select>
                                                            )}
                                                        </Field>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    display: { xs: "none", md: "flex" },
                                                }}>
                                                    <DragDropContext onDragEnd={onDragEnd}>
                                                        <Grid container spacing={1.5} columns={7} mt={0.5}
                                                            sx={{ width: "100%" }}>
                                                            {dayRange.map(date => (
                                                                <Grid xs key={getISODay(date)}
                                                                    sx={{ height: "35vh" }}>
                                                                    <Typography
                                                                        textAlign="center"
                                                                        textColor="neutral.500"
                                                                        fontWeight={700}
                                                                        mt={2}
                                                                        sx={{
                                                                            fontSize: '10px',
                                                                            textTransform: 'uppercase',
                                                                            letterSpacing: '0.1rem'
                                                                        }}
                                                                    >
                                                                        {formatDOW(date)}
                                                                    </Typography>
                                                                    <Droppable
                                                                        droppableId={getISODay(date).toString()}>
                                                                        {provided => (
                                                                            <Box
                                                                                sx={{ height: "100%" }}
                                                                                {...provided.droppableProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                <List>
                                                                                    {(eventByDow[getISODay(date)] || []).map((it, index) => (
                                                                                        <Draggable
                                                                                            draggableId={[getISODay(date), it].join(":")}
                                                                                            index={index}>
                                                                                            {provided => (
                                                                                                <div
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                >
                                                                                                    <Item
                                                                                                        sportType={it} />
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                </List>
                                                                                <Box
                                                                                    sx={{ height: "100%" }}>{provided.placeholder}</Box>
                                                                            </Box>
                                                                        )}
                                                                    </Droppable>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </DragDropContext>
                                                </Box>
                                                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                    <Button color="success" size="lg"
                                                        type="submit"
                                                        endDecorator={<KeyboardArrowRight />}
                                                    >
                                                        {t("form_button__create_plan")}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </FormSpy>
                                </Box>
                            </Box>
                        </form>
                    )}
                </Form>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={saving}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </ModalDialog>
        </ModalOverflow>
    )
}

export default PlanBuilderDialog
