import { useAppState } from "../../../features/app/hooks";
import AlertItem from "./AlertItem";
import { IAlert } from "../../../app/types/Alert";


function Alerts() {
  const { alerts } = useAppState()

  return (
    <>
      {alerts.map((item: IAlert, id: number) => <AlertItem alert={item} id={id} />)}
    </>
  );
}

export default Alerts;
